


























































































































































@keyframes inner {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes outer {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  50% {
    transform: scale(0.9);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.opt-img {
  position: fixed;
  right: 112px;
  width: 300px;
}
.opt1 {
  top: 20%;
}

.opt2 {
  top: 30%;
}

.tools-wrapper {
  position: fixed;
  z-index: 40;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  .im-consult {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    .avatar-wrapper {
      box-sizing: border-box;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 88px;
      height: 88px;
      border-radius: 50%;
      .ani-circle {
        box-sizing: border-box;
        position: absolute;
        left: 0;
        top: 0;
        width: 88px;
        height: 88px;
        border: 1px solid rgba(255, 102, 0, 0.35);
        border-radius: 50%;
        opacity: 0;
        animation: 1.2s ease-out 1.2s infinite outer;
      }
      .avatar {
        width: 68px;
        height: 68px;
        border-radius: 50%;
        border: 2px solid #ff6600;
        // background-image: url(https://mktv-in-cdn.mockuai.com/16240060133929089.png);
        // background-repeat: no-repeat;
        // background-size: cover;
        img {
          width: 100%;
          height: 100%;
          animation: 1.2s ease-out 1.2s infinite inner;
        }
      }
    }
    .text {
      box-sizing: border-box;
      position: relative;
      top: -8px;
      width: 72px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      border: 1px solid #fff;
      background: rgba(255, 255, 255, 0.9);
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      border: 1px solid #ffffff;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      &:hover {
        color: #ff6600;
      }
    }
  }
  .options {
    box-sizing: border-box;
    // margin-top: 10px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: 1px solid #ffffff;
    .option-item {
      width: 72px;
      height: 72px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #ddd;
      cursor: pointer;
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        .text {
          color: #ff6600;
        }
      }
      &:nth-child(1) {
        .icon {
          width: 32px;
          height: 32px;
        }
      }
      &:nth-child(2) {
        .icon {
          width: 32px;
          height: 33px;
        }
      }
      &:nth-child(3) {
        .icon {
          width: 22px;
          height: 23px;
        }
      }
      &:nth-child(4) {
        .icon {
          width: 32px;
          height: 32px;
        }
      }
      .icon {
        object-fit: cover;
        margin-bottom: 2px;
      }
      .text {
        line-height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
  }
  .backtop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    margin-top: 8px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    &:hover {
      .text {
        color: #ff6600;
      }
    }
    .icon {
      width: 32px;
      height: 32px;
      margin-bottom: 1px;
    }
    .text {
      line-height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333;
    }
  }
}
