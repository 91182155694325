.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
.private-wrapper .banner-wrapper {
  position: relative;
  box-sizing: border-box;
  height: 35.41666666666667vw;
  padding-top: 8.125vw;
  padding-left: 12.5vw;
  background-color: #00092a;
  background-image: url(https://mktv-in-cdn.mockuai.com/16281416966898573.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.private-wrapper .banner-wrapper .info h3.title {
  margin-bottom: 0.8854166666666666vw;
  line-height: 1.4;
  font-size: 3.125vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
}
.private-wrapper .banner-wrapper .info .desc {
  margin-bottom: 4.739583333333333vw;
  line-height: 1.7187500000000002vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
}
.private-wrapper .banner-wrapper .info .actions {
  display: flex;
  align-items: center;
}
.private-wrapper .banner-wrapper .info .actions .btn {
  box-sizing: border-box;
  margin-right: 3.125vw;
  height: 3.125vw;
  line-height: 3.125vw;
  text-align: center;
  padding: 0 2.29166667vw;
  border-radius: 1.5625vw;
  border: 1px solid #fff;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
}
.private-wrapper .banner-wrapper .info .actions .btn:last-child {
  margin-right: 0;
}
.private-wrapper .banner-wrapper .info .actions .btn:hover {
  font-weight: 600;
}
.private-wrapper .banner-wrapper .info .actions .btn.btn-white {
  background-color: #fff;
  color: #0F58F0;
}
.private-wrapper .banner-wrapper .info .actions .btn.btn-white:hover {
  border: none;
  background: linear-gradient(270deg, #1AD4E4 0%, #0F58F0 100%);
  color: #fff;
}
.private-wrapper .banner-wrapper .banner-box {
  position: absolute;
  top: 8.489583333333334vw;
  right: 17.604166666666668vw;
  width: 30.364583333333332vw;
  height: 18.489583333333336vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16267622862671934.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.private-wrapper .banner-wrapper .banner-box .icon-box {
  position: absolute;
  box-sizing: border-box;
  width: 4.791666666666667vw;
  height: 5.625vw;
  padding: 0.78125vw 0.88541667vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  box-shadow: 5px 11px 23px 0px rgba(0, 0, 0, 0.11);
  border-radius: 0.4166666666666667vw;
}
.private-wrapper .banner-wrapper .banner-box .icon-box:nth-child(1) {
  left: -4.166666666666666vw;
  top: 3.3333333333333335vw;
}
.private-wrapper .banner-wrapper .banner-box .icon-box:nth-child(2) {
  left: -2.34375vw;
  bottom: 1.3020833333333335vw;
}
.private-wrapper .banner-wrapper .banner-box .icon-box:nth-child(3) {
  right: -3.6979166666666665vw;
  top: 3.3333333333333335vw;
}
.private-wrapper .banner-wrapper .banner-box .icon-box:nth-child(4) {
  right: -1.9791666666666665vw;
  bottom: 1.3020833333333335vw;
}
.private-wrapper .banner-wrapper .banner-box .icon-box .icon {
  width: 3.0208333333333335vw;
  height: 3.0208333333333335vw;
}
.private-wrapper .banner-wrapper .banner-box .icon-box .text {
  font-size: 0.7291666666666666vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.private-wrapper .intro-wrapper {
  box-sizing: border-box;
  height: 55.52083333333333vw;
  padding-top: 4.427083333333334vw;
  padding-bottom: 5.208333333333334vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16265063620297857.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.private-wrapper .intro-wrapper > .title {
  text-align: center;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.private-wrapper .intro-wrapper .desc {
  margin: 0.41666667vw auto 0;
  width: 35.67708333333333vw;
  text-align: center;
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}
.private-wrapper .intro-wrapper .functions {
  margin-top: 4.53125vw;
  margin-left: 12.5vw;
  display: flex;
  align-items: flex-end;
}
.private-wrapper .intro-wrapper .functions .function-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.private-wrapper .intro-wrapper .functions .function-item:nth-child(1) {
  margin-right: 3.6458333333333335vw;
}
.private-wrapper .intro-wrapper .functions .function-item:nth-child(1) .image {
  width: 24.6875vw;
  height: 21.197916666666668vw;
  margin-bottom: 3.125vw;
}
.private-wrapper .intro-wrapper .functions .function-item:nth-child(2) {
  margin-right: 2.1354166666666665vw;
}
.private-wrapper .intro-wrapper .functions .function-item:nth-child(2) .image {
  width: 18.333333333333332vw;
  height: 18.333333333333332vw;
  margin-bottom: 4.21875vw;
}
.private-wrapper .intro-wrapper .functions .function-item:nth-child(3) {
  margin-right: 0;
}
.private-wrapper .intro-wrapper .functions .function-item:nth-child(3) .image {
  width: 26.25vw;
  height: 20.833333333333336vw;
  margin-bottom: 2.864583333333333vw;
}
.private-wrapper .intro-wrapper .functions .function-item .image {
  object-fit: cover;
}
.private-wrapper .intro-wrapper .functions .function-item .text {
  width: 9.375vw;
  height: 3.0208333333333335vw;
  line-height: 3.0208333333333335vw;
  text-align: center;
  border-radius: 1.875vw;
  background-color: #eaf0ff;
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.private-wrapper .intro-wrapper .btn {
  margin: 5.20833333vw auto 0;
  width: 16.666666666666664vw;
  height: 3.125vw;
  line-height: 3.125vw;
  text-align: center;
  background-color: #0f58f0;
  border-radius: 1.5625vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
}
.private-wrapper .intro-wrapper .btn:hover {
  font-weight: 500;
  color: #fff;
  box-shadow: 0px 8px 18px 0px rgba(15, 88, 240, 0.21);
}
