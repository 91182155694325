.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
@keyframes fade-in-animation {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.fade-in-animation {
  animation: fade-in-animation 0.4s ease 80ms 1 normal both;
}
.invigorate-wrapper {
  height: 52.083333333333336vw;
}
.invigorate-wrapper .banner-container {
  box-sizing: border-box;
  height: 27.083333333333332vw;
  padding-top: 4.895833333333333vw;
  background: no-repeat url(https://mktv-in-cdn.mockuai.com/16266603265779075.png) left top / cover, linear-gradient(133deg, #00092A 0%, #001554 100%);
}
.invigorate-wrapper .banner-container > .title {
  margin-bottom: 3.958333333333333vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
}
.invigorate-wrapper .banner-container .tabs-container {
  margin: 0 12.5vw;
}
.invigorate-wrapper .banner-container .tabs-container .tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.083333333333333vw;
}
.invigorate-wrapper .banner-container .tabs-container .tabs .tab-item {
  box-sizing: border-box;
  width: 9.375vw;
  height: 3.125vw;
  line-height: 3.125vw;
  margin-right: 2.083333333333333vw;
  text-align: center;
  border-radius: 1.5625vw;
  border: 1px solid #fff;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
}
.invigorate-wrapper .banner-container .tabs-container .tabs .tab-item.active {
  background: linear-gradient(270deg, #1AD4E4 0%, #0F58F0 100%);
  border: none;
}
.invigorate-wrapper .banner-container .tabs-container .tabs .tab-item:last-child {
  margin-right: 0;
}
.invigorate-wrapper .banner-container .tabs-container .content-box {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 31.25vw;
  padding-left: 1.8229166666666667vw;
  padding-top: 4.895833333333333vw;
  border-radius: 0.8333333333333334vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16266629725051762.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.invigorate-wrapper .banner-container .tabs-container .content-box .info .details {
  margin-bottom: 3.0208333333333335vw;
  overflow: hidden;
}
.invigorate-wrapper .banner-container .tabs-container .content-box .info .details .detail-item {
  display: flex;
  align-items: center;
  margin-bottom: 3.75vw;
}
.invigorate-wrapper .banner-container .tabs-container .content-box .info .details .detail-item .icon {
  width: 3.0208333333333335vw;
  height: 3.0208333333333335vw;
  object-fit: cover;
  margin-right: 0.9895833333333333vw;
}
.invigorate-wrapper .banner-container .tabs-container .content-box .info .details .detail-item .right .text {
  margin-bottom: 0.46875vw;
  line-height: 1.7187500000000002vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.invigorate-wrapper .banner-container .tabs-container .content-box .info .details .detail-item .right .desc {
  max-width: 19.791666666666664vw;
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}
.invigorate-wrapper .banner-container .tabs-container .content-box .info .btn {
  width: 16.666666666666664vw;
  height: 3.125vw;
  line-height: 3.125vw;
  text-align: center;
  box-shadow: 0px 8px 18px 0px rgba(15, 88, 240, 0.21);
  background-color: #0f58f0;
  border-radius: 1.5625vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
}
.invigorate-wrapper .banner-container .tabs-container .content-box .info .btn:hover {
  font-weight: 500;
  color: #fff;
}
.invigorate-wrapper .banner-container .tabs-container .content-box .banner-box {
  position: absolute;
  top: 2.1875vw;
  right: -0.6770833333333334vw;
  width: 45.989583333333336vw;
  height: 28.59375vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16275788344987534.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.invigorate-wrapper .banner-container .tabs-container .content-box .banner-box .banner {
  position: absolute;
  top: 2.65625vw;
  left: 6.041666666666667vw;
  width: 31.71875vw;
  height: 19.895833333333332vw;
}
