.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
.drainage-wrapper .panels-wrapper {
  box-sizing: border-box;
  height: 49.6875vw;
  padding-top: 5.833333333333333vw;
}
.drainage-wrapper .panels-wrapper > .title {
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  margin-bottom: 3.75vw;
  text-align: center;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.drainage-wrapper .panels-wrapper .slides-container {
  margin: 0 12.5vw;
  display: flex;
  align-items: center;
}
.drainage-wrapper .panels-wrapper .slides-container .slide-item {
  box-sizing: border-box;
  transition: all 0.35s;
  position: relative;
  width: 15.625vw;
  height: 31.25vw;
  padding: 1.61458333vw 2.08333333vw 0;
  margin-right: 1.0416666666666665vw;
  border-radius: 0.8333333333333334vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #001554;
}
.drainage-wrapper .panels-wrapper .slides-container .slide-item:last-child {
  margin-right: 0;
}
.drainage-wrapper .panels-wrapper .slides-container .slide-item.multi-channel {
  background-image: url(https://mktv-in-cdn.mockuai.com/16265143504747111.png);
}
.drainage-wrapper .panels-wrapper .slides-container .slide-item.multi-scene {
  background-image: url(https://mktv-in-cdn.mockuai.com/16265143504556779.png);
}
.drainage-wrapper .panels-wrapper .slides-container .slide-item.no-limit {
  background-image: url(https://mktv-in-cdn.mockuai.com/16265143504202707.png);
}
.drainage-wrapper .panels-wrapper .slides-container .slide-item.active {
  width: 41.66666666666667vw;
}
.drainage-wrapper .panels-wrapper .slides-container .slide-item.active.multi-channel {
  background-image: url(https://mktv-in-cdn.mockuai.com/16265143503505476.png);
}
.drainage-wrapper .panels-wrapper .slides-container .slide-item.active.multi-scene {
  background-image: url(https://mktv-in-cdn.mockuai.com/16265143504658915.png);
}
.drainage-wrapper .panels-wrapper .slides-container .slide-item.active.no-limit {
  background-image: url(https://mktv-in-cdn.mockuai.com/16265143504772838.png);
}
.drainage-wrapper .panels-wrapper .slides-container .slide-item.active.no-limit .functions {
  width: 24.583333333333332vw;
}
.drainage-wrapper .panels-wrapper .slides-container .slide-item.active.no-limit .functions .function-item:nth-child(2n) {
  margin-right: 0;
}
.drainage-wrapper .panels-wrapper .slides-container .slide-item.active.no-limit .functions .function-item:nth-child(3n) {
  margin-right: 1.25vw;
}
.drainage-wrapper .panels-wrapper .slides-container .slide-item .name {
  position: absolute;
  left: 2.083333333333333vw;
  bottom: 2.1875vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
}
.drainage-wrapper .panels-wrapper .slides-container .slide-item .info {
  overflow: hidden;
}
.drainage-wrapper .panels-wrapper .slides-container .slide-item .info.fade-enter-active,
.drainage-wrapper .panels-wrapper .slides-container .slide-item .info .fade-leave-active {
  transition: opacity 1s;
}
.drainage-wrapper .panels-wrapper .slides-container .slide-item .info.fade-enter,
.drainage-wrapper .panels-wrapper .slides-container .slide-item .info .fade-leave-to {
  opacity: 0;
}
.drainage-wrapper .panels-wrapper .slides-container .slide-item .info .title {
  width: 26.25vw;
  margin: 0;
  margin-bottom: 2.2395833333333335vw;
  font-size: 1.6666666666666667vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
}
.drainage-wrapper .panels-wrapper .slides-container .slide-item .info .functions {
  display: flex;
  flex-wrap: wrap;
  width: 37.5vw;
  height: 16.875vw;
}
.drainage-wrapper .panels-wrapper .slides-container .slide-item .info .functions .function-item {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 11.666666666666666vw;
  height: 7.187499999999999vw;
  margin-right: 1.25vw;
  margin-bottom: 1.25vw;
  border-radius: 0.4166666666666667vw;
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.33);
  backdrop-filter: blur(8px);
  cursor: pointer;
}
.drainage-wrapper .panels-wrapper .slides-container .slide-item .info .functions .function-item:nth-child(3n) {
  margin-right: 0;
}
.drainage-wrapper .panels-wrapper .slides-container .slide-item .info .functions .function-item:hover {
  background-color: #fff;
}
.drainage-wrapper .panels-wrapper .slides-container .slide-item .info .functions .function-item:hover .text {
  color: #333;
}
.drainage-wrapper .panels-wrapper .slides-container .slide-item .info .functions .function-item .icon {
  width: 3.0208333333333335vw;
  height: 3.0208333333333335vw;
  margin-bottom: 0.4166666666666667vw;
}
.drainage-wrapper .panels-wrapper .slides-container .slide-item .info .functions .function-item .text {
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
}
.drainage-wrapper .panels-wrapper .slides-container .slide-item .btn {
  box-sizing: border-box;
  position: absolute;
  right: 2.083333333333333vw;
  bottom: 1.6666666666666667vw;
  width: 10.416666666666668vw;
  height: 2.5vw;
  line-height: 2.5vw;
  text-align: center;
  border-radius: 1.1979166666666667vw;
  background-color: #fff;
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
}
.drainage-wrapper .panels-wrapper .slides-container .slide-item .btn:hover {
  background: linear-gradient(270deg, #1AD4E4 0%, #0F58F0 100%);
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #fff;
}
