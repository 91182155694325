






















































































































































.box {
  display: flex;
  align-items: center;
  .icon {
    width: 28px;
    height: 28px;
    margin-right: 9px;
  }
  .text {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #0f58f0;
  }
}
.nav { 
  .nav-item {
    &.active {
      a {
        &::after {
          content: '';
          background-color: #0f58f0 !important;
        }
      }
    }
  }
}
.scrm-container {
  .section {
    transition: all .5s;
    &:nth-child(1) {
      padding-top: 150px;
    }
    &:nth-child(2) {
      padding-top: 60px;
    }
    &:nth-child(3) {
      padding-top: 60px;
    }
    &:nth-child(4) {
      padding-top: 60px;
    }
  }
}
