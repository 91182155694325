.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fade-in {
  animation: fade-in 1s ease 0s 1 normal both;
}
.fans-wrapper .lifecycle-container {
  box-sizing: border-box;
  height: 53.125vw;
  padding-top: 4.791666666666667vw;
  background: linear-gradient(133deg, #00092A 0%, #001554 100%);
}
.fans-wrapper .lifecycle-container > .title {
  margin-bottom: 3.90625vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
}
.fans-wrapper .lifecycle-container .content-box {
  margin: 0 12.5vw;
}
.fans-wrapper .lifecycle-container .content-box .tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.083333333333333vw;
}
.fans-wrapper .lifecycle-container .content-box .tabs .tab-item {
  box-sizing: border-box;
  margin-right: 2.083333333333333vw;
  width: 9.375vw;
  height: 3.125vw;
  line-height: 3.125vw;
  text-align: center;
  border-radius: 1.5625vw;
  border: 1px solid #fff;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fff;
}
.fans-wrapper .lifecycle-container .content-box .tabs .tab-item.active {
  background: linear-gradient(270deg, #1AD4E4 0%, #0F58F0 100%);
  border: none;
  color: #fff;
}
.fans-wrapper .lifecycle-container .content-box .tabs .tab-item:last-child {
  margin-right: 0;
}
.fans-wrapper .lifecycle-container .content-box .tab-panel {
  box-sizing: border-box;
  height: 31.25vw;
  padding-left: 41.66666666666667vw;
  padding-top: 4.010416666666667vw;
  border-radius: 0.8333333333333334vw;
  background-size: cover;
  background-repeat: no-repeat;
}
.fans-wrapper .lifecycle-container .content-box .tab-panel.product {
  background: no-repeat url(https://mktv-in-cdn.mockuai.com/16266671317575378.png) top left / cover, linear-gradient(133deg, #00092A 0%, #001554 100%);
}
.fans-wrapper .lifecycle-container .content-box .tab-panel.product .btn {
  margin-top: 4.010416666666667vw;
}
.fans-wrapper .lifecycle-container .content-box .tab-panel.data {
  padding-top: 6.510416666666667vw;
  background: no-repeat url(https://mktv-in-cdn.mockuai.com/16266671317869193.png) top left / cover, linear-gradient(133deg, #00092A 0%, #001554 100%);
}
.fans-wrapper .lifecycle-container .content-box .tab-panel.data .btn {
  margin-top: 6.458333333333334vw;
}
.fans-wrapper .lifecycle-container .content-box .tab-panel.risk {
  background: no-repeat url(https://mktv-in-cdn.mockuai.com/16266671317915413.png) top left / cover, linear-gradient(133deg, #00092A 0%, #001554 100%);
}
.fans-wrapper .lifecycle-container .content-box .tab-panel.risk .btn {
  margin-top: 2.7083333333333335vw;
}
.fans-wrapper .lifecycle-container .content-box .tab-panel.risk .details .detail-item {
  margin-bottom: 1.40625vw;
}
.fans-wrapper .lifecycle-container .content-box .tab-panel .details .detail-item {
  display: flex;
  align-items: center;
  margin-bottom: 2.7083333333333335vw;
}
.fans-wrapper .lifecycle-container .content-box .tab-panel .details .detail-item:last-child {
  margin-bottom: 0;
}
.fans-wrapper .lifecycle-container .content-box .tab-panel .details .detail-item .icon {
  width: 3.0208333333333335vw;
  height: 3.0208333333333335vw;
  margin-right: 0.9895833333333333vw;
}
.fans-wrapper .lifecycle-container .content-box .tab-panel .details .detail-item .right .text {
  margin-bottom: 0.46875vw;
  line-height: 1.7187500000000002vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
}
.fans-wrapper .lifecycle-container .content-box .tab-panel .details .detail-item .right .desc {
  margin: 0;
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
}
.fans-wrapper .lifecycle-container .content-box .tab-panel .btn {
  width: 16.666666666666664vw;
  height: 3.125vw;
  line-height: 3.125vw;
  text-align: center;
  border-radius: 1.5625vw;
  background-color: #fff;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
}
.fans-wrapper .lifecycle-container .content-box .tab-panel .btn:hover {
  background: linear-gradient(270deg, #1AD4E4 0%, #0F58F0 100%);
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
}
.fans-wrapper .industry-container {
  box-sizing: border-box;
  height: 39.791666666666664vw;
  padding-top: 4.739583333333333vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16266790343005875.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.fans-wrapper .industry-container > .title {
  margin-bottom: 3.802083333333333vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.fans-wrapper .industry-container .content-box {
  margin: 0 12.5vw;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.fans-wrapper .industry-container .content-box .card-item {
  box-sizing: border-box;
  position: relative;
  width: 14.166666666666666vw;
  height: 8.75vw;
  padding-top: 1.6666666666666667vw;
  padding-bottom: 1.875vw;
  margin-right: 1.0416666666666665vw;
  margin-bottom: 1.0416666666666665vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.4166666666666667vw;
  overflow: hidden;
  cursor: pointer;
}
.fans-wrapper .industry-container .content-box .card-item:nth-child(5n) {
  margin-right: 0;
}
.fans-wrapper .industry-container .content-box .card-item .icon {
  width: 3.0208333333333335vw;
  height: 3.0208333333333335vw;
  margin-bottom: 0.46875vw;
}
.fans-wrapper .industry-container .content-box .card-item .text {
  line-height: 1.7187500000000002vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.fans-wrapper .industry-container .content-box .card-item .cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0f58f0;
}
.fans-wrapper .industry-container .content-box .card-item .cover .image {
  width: 100%;
}
.fans-wrapper .industry-container .content-box .card-item .cover .text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.25vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
}
.fans-wrapper .use-container {
  box-sizing: border-box;
  height: 21.666666666666668vw;
  padding-top: 5.885416666666667vw;
  background-color: #0F58F0;
  background-image: url(https://mktv-in-cdn.mockuai.com/16266792527479141.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.fans-wrapper .use-container .title {
  margin-bottom: 3.802083333333333vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
}
.fans-wrapper .use-container .btn {
  margin: 0 auto;
  width: 16.666666666666664vw;
  height: 3.125vw;
  line-height: 3.125vw;
  text-align: center;
  border-radius: 1.5625vw;
  background-color: #fff;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
}
.fans-wrapper .use-container .btn:hover {
  font-weight: 600;
}
