












































.shrinkable-header {
  position: fixed;
  top: 0;
  width: 100%;
  transition: all 0.35s;
  // overflow: hidden;
  z-index: 100;
}
